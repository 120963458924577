<template>
  <div class="row">
    <div class="col-12">
      <ibox
        :title="this.$t('nav.training-categories-list')"
        :collapseId="'collapse-2'"
      >
        <template slot="tools">
          <router-link
            :to="
              `/${this.$route.params.shop_lang}/${this.$route.params.shop_name}/courses/cities/add`
            "
            class="btn btn-primary btn-xs"
          >
            Dodaj miasto
            <i class="fas fa-plus"></i>
          </router-link>
        </template>
        <template slot="content" slot-scope="{ setLoading }">
          <DataTable :url="url" @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "TrainingCitiesList",
  components: {
    Ibox,
    DataTable
  },
  data() {
    return {
      excel: true
    };
  },
  computed: {
    url() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/cities`;
    }
  }
};
</script>
